import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components";
import * as styles from "../css/styles.module.css"

const AboutWrapper = styled.div`    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;

const ContentWrapper = styled.div`
    align-self: center;
`;


const AboutUs = () => (
  <Layout>
    <Seo title="About Us" />
    <AboutWrapper>
        <ContentWrapper>
        <h1>About page</h1>
        <p>Welcome to the about page</p>
        <Link to="/">Go back to the homepage</Link>
        </ContentWrapper>
    </AboutWrapper>
  </Layout>
)

export default AboutUs
